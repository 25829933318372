<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
        <button class="button is-pulled-right is-small is-turquoise"
          @click="gotoV1">
          <span class="icon">
            <i class="mdi mdi-arrow-right" />
          </span>
          <span>V1</span>
        </button>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Invoice No."
                v-model="value.invoiceNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Debtor"
                v-model="value.debtor"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Quote No."
                v-model="value.quoteNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <input class="is-checkradio"
              checked
              id="nonzerobalance"
              type="radio"
              name="FilterType"
              value="1"
              v-model="value.filterType">
            <label for="nonzerobalance">Invoices with Non-Zero Balance Only</label>
          </div>
          <div class="field">
            <input class="is-checkradio"
              id="lastsixmonth"
              type="radio"
              name="FilterType"
              value="2"
              v-model="value.filterType">
            <label for="lastsixmonth">All Invoices within Last 6 Months</label>
          </div>
          <div class="field">
            <input class="is-checkradio"
              id="allinvoice"
              type="radio"
              name="FilterType"
              value="0"
              v-model="value.filterType">
            <label for="allinvoice">All Invoices</label>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ReceiptEntryListSideMenu',
  components: {
    AppSideMenu
  },
  props: {
    filter: null,
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      invoiceNo: null,
      debtor: null,
      quoteNo: null
    }
  },
  computed: {},
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    }
  },
  created() {},
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.$emit('reset')
    },
    gotoV1() {
      window.open(`${process.env.VUE_APP_ROOT_URI}/Modules/ReceiptEntryView/ReceiptEntry.aspx`, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
